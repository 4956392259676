export default class Paper {
  constructor() {
    this.paperType = null;
    this.employee = null;
    this.issueDate = null;
    this.expireDate = null;
    this.url = null;
    this.description = null;
  }
}
