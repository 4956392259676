<template>
  <app-page-layout :title="$t('hr.paper.add_paper')" @close="leavePage">
    <template #content>
      <v-card :disabled="loading" flat>
        <validation-observer ref="form">
          <v-flex xs12>
            <validation-provider :name="$t('hr.paper.type')" rules="required" v-slot="{ errors }">
              <paper-type-picker v-model="paper.paperType" :error-messages="errors" />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('global.file')" rules="required" v-slot="{ errors }">
              <file-upload-picker
                on-create
                storageType="PAPER"
                @send="cb => (onFileSend = cb)"
                v-model="paper.url"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>

          <v-flex xs12>
            <v-menu
              ref="issueDateCalendar"
              :close-on-content-click="false"
              v-model="issueDateCalendarState"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="paper.issueDate"
                  color="tertiary"
                  :label="$t('hr.paper.papers.issue_date')"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                :locale="$store.state.auth.language"
                v-model="paper.issueDate"
                color="primary"
                :first-day-of-week="1"
                scrollable
                @input="issueDateCalendarState = false"
              />
            </v-menu>
          </v-flex>
          <v-flex xs12>
            <v-menu
              ref="expireDateCalendar"
              :close-on-content-click="false"
              v-model="expireDateCalendarState"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="paper.expireDate"
                  color="tertiary"
                  :label="$t('hr.paper.papers.expire_date')"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="paper.expireDate"
                color="primary"
                scrollable
                :first-day-of-week="1"
                :locale="$store.state.auth.language"
                @input="expireDateCalendarState = false"
                :min="paper.issueDate"
              />
            </v-menu>
          </v-flex>

          <v-flex xs12>
            <validation-provider :name="$t('hr.note.description')" rules="required" v-slot="{ errors }">
              <v-text-field v-model="paper.description" :label="$t('hr.note.description')" :error-messages="errors" />
            </validation-provider>
          </v-flex>
        </validation-observer>
      </v-card>
    </template>
    <template #footer>
      <app-page-footer>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import Paper from "../model/Paper";

  export default {
    name: "PaperCreate",
    components: {
      "paper-type-picker": () => import("../components/paper-type-picker"),
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    data: () => ({
      expireDateCalendarState: false,
      issueDateCalendarState: false,
      loading: false,
      paper: new Paper(),
      onFileSend: () => {}
    }),
    methods: {
      date(val) {
        return this.$moment(val).format("YYYY-MM-DD");
      },
      leavePage() {
        this.$router.push({ name: "papers" });
      },
      validateAndSave() {
        this.$refs.form
          .validate()
          .then(valid => {
            if (valid) {
              this.loading = true;
              this.paper.employee = this.$store.state.auth.user.id;
              this.onFileSend().then(uploaded => {
                if (uploaded) {
                  this.$api.paperService
                    .save(this.paper)
                    .then(({ data }) => {
                      if (!data.error) {
                        this.$emit("success:save");
                        this.leavePage();
                      } else this.$helpers.showNotification(data.error.message);
                    })
                    .catch(e => {
                      this.$helpers.showNotification(e.message);
                    })
                    .finally(() => {
                      this.loading = false;
                    });
                }
              });
            }
          })
          .catch(error => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
